import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  boxWrapper: {
    zIndex: '100',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0 30px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 25px',
    },
  },
  colorBar: {
    width: '100%',
    maxWidth: '1000px',
    height: '10px',
    background: 'linear-gradient(90deg, #63bdf3, #73da95)',
    [theme.breakpoints.down('md')]: {
      maxWidth: '884px',
    },
  },
  textContainer: {
    width: '100%',
    maxWidth: '1000px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '12px 120px 0',
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      maxWidth: '884px',
      padding: '8px 100px 0',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 30px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 10px',
    },
  },
}));

export default useStyles;
