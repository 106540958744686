import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GatsbyImagePropTypes from '@prop-types/gatsby-image';
import { AnimatePresence, motion } from 'framer-motion';
import { ANIM } from '@constants';
import MoeyHistoryMilestone from './MoeyHistoryMilestone';
import MoeyHistoryItem from './MoeyHistoryItem';
import MoeyHistoryController from './MoeyHistoryController';
import useStyles from './styles';

const MoeyHistorySection = ({ historyItems }) => {
  const classes = useStyles();
  const [historyIndex, setHistoryIndex] = useState(0);
  const [transitionDir, setTransitionDir] = useState('right');
  const [isAnimating, setAnimating] = useState(false);

  const onArrowButtonClick = ({ left }) => {
    if (isAnimating) return;
    const value = left ? -1 : 1;
    let nextIndex = historyIndex + value;
    if (nextIndex > historyItems.length - 1) {
      nextIndex = 0;
    } else if (nextIndex < 0) {
      nextIndex = historyItems.length - 1;
    }
    setHistoryIndex(nextIndex);
    setTransitionDir(left ? 'left' : 'right');
    setAnimating(true);
  };

  return (
    <section className={classes.root}>
      <MoeyHistoryMilestone
        milestone={historyItems[historyIndex].milestone}
      />
      <div className={classes.container}>
        <div className={classes.inner}>
          <MoeyHistoryController
            onArrowButtonClick={onArrowButtonClick}
          />
          <AnimatePresence
            initial={false}
            custom={{ dir: transitionDir }}
          >
            <motion.div
              key={historyIndex}
              initial={{
                x: transitionDir === 'right' ? '100%' : '-100%',
                opacity: 0,
              }}
              animate={{ x: 0, opacity: 1 }}
              exit={({ dir }) => ({
                x: dir === 'right' ? '-100%' : '100%',
                opacity: 0,
              })}
              transition={{
                duration: 0.4,
                ease: ANIM.easeInOutQuad,
              }}
              onAnimationComplete={() => setAnimating(false)}
              className={classes.historyItemWrapper}
            >
              <MoeyHistoryItem
                image={historyItems[historyIndex].image.fluid}
                heading={historyItems[historyIndex].heading}
                description={historyItems[historyIndex].description.description}
              />
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </section>
  );
};

MoeyHistorySection.propTypes = {
  historyItems: PropTypes.arrayOf(PropTypes.shape({
    milestone: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    description: PropTypes.shape({
      description: PropTypes.string.isRequired,
    }).isRequired,
    image: PropTypes.shape({
      fluid: GatsbyImagePropTypes.fluid.isRequired,
    }).isRequired,
  })).isRequired,
};

export default MoeyHistorySection;
