import React from 'react';
import PropTypes from 'prop-types';
import ArrowIcon from '@images/arrow.inline.svg';
import useStyles from './styles';

const ArrowButton = ({ size, left, onClick }) => {
  const classes = useStyles({ size });

  return (
    <button
      className={`${classes.root} ${left ? 'left' : 'right'}`}
      onClick={() => onClick({ left })}
      type="button"
    >
      <ArrowIcon
        className={`${classes.arrow} ${left ? 'left' : 'right'}`}
      />
    </button>
  );
};

ArrowButton.defaultProps = {
  size: 80,
  left: false,
};

ArrowButton.propTypes = {
  size: PropTypes.number,
  left: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default ArrowButton;
