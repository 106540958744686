import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  borderContainer: {
    position: 'relative',
    height: '45px',
    marginBottom: '60px',
  },
  border: {
    position: 'absolute',
    top: '50%',
    width: '100%',
    borderWidth: '0.5px',
    borderColor: '#e2e2e2',
    margin: '0',
  },
  milestone: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '0 2.5rem',
    whiteSpace: 'nowrap',
  },
  invisibleText: {
    opacity: 0,
  },
  text: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  },
}));

export default useStyles;
