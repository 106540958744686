import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '40px',
    [theme.breakpoints.down('md')]: {
      padding: '30px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      zIndex: '-10',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      mixBlendMode: 'screen',
      background: (props) => (props.odd
        ? 'linear-gradient(-45deg, #c8e970, #63bdf3)'
        : 'linear-gradient(-45deg, #63bdf3, #c8e970)'),
    },
  },
  inner: {
    padding: '50px',
    backgroundColor: 'white',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      padding: '40px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '25px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
  message: {
    marginBottom: '30px',
    whiteSpace: 'pre-wrap',
  },
}));

export default useStyles;
