import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import GatsbyImage from 'gatsby-image';
import GatsbyImagePropTypes from '@prop-types/gatsby-image';
import useStyles from './styles';

const MoeyHistoryItem = ({ heading, description, image }) => {
  const classes = useStyles();

  return (
    <div className={classes.historyItem}>
      <div className={classes.imageWrapper}>
        <GatsbyImage
          fluid={image}
        />
      </div>
      <div className={classes.textContainer}>
        <Typography variant="h3" className={classes.heading}>
          { heading }
        </Typography>
        <Typography variant="body2" className={classes.description}>
          { description }
        </Typography>
      </div>
    </div>
  );
};

MoeyHistoryItem.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: GatsbyImagePropTypes.fluid.isRequired,
};

export default MoeyHistoryItem;
