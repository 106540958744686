import React from 'react';
import GatsbyImage from 'gatsby-image';
import GatsbyImagePropTypes from '@prop-types/gatsby-image';
import useStyles from './styles';

const MollyJoeyBackground = ({ backgroundImage }) => {
  const classes = useStyles();

  return (
    <div className={classes.backgroundContainer}>
      <GatsbyImage
        style={{ height: '100%' }}
        fluid={backgroundImage}
        alt="background"
      />
    </div>
  );
};

MollyJoeyBackground.propTypes = {
  backgroundImage: GatsbyImagePropTypes.fluid.isRequired,
};

export default MollyJoeyBackground;
