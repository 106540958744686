import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  arrowButtonWrapper: {
    position: 'absolute',
    zIndex: 100,
    top: '120px',
    transform: 'translateY(-50%)',
    [theme.breakpoints.down('md')]: {
      top: '96px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 30px',
    },
    [theme.breakpoints.down('xs')]: {
      top: '72px',
      margin: '0 25px',
    },
  },
  left: {
    left: '0',
  },
  right: {
    right: '0',
  },
}));

export default useStyles;
