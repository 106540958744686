import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: (props) => ({
    width: `${props.size}px`,
    height: `${props.size}px`,
    borderRadius: `${props.size / 2}px`,
    backgroundColor: 'white',
    position: 'relative',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
    border: 'none',
    cursor: 'pointer',
  }),
  arrow: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: '45.175%',
    '&.left': {
      transform: 'translate(-50%, -50%) rotate(180deg)',
    },
    '&.right': {
      transform: 'translate(-50%, -50%)',
    },
  },
}));

export default useStyles;
