import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  historyItem: {
    maxWidth: '700px',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      maxWidth: '624px',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  imageWrapper: {
    width: '240px',
    minWidth: '240px',
    height: '240px',
    [theme.breakpoints.down('md')]: {
      width: '192px',
      minWidth: '192px',
      height: '192px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '144px',
      minWidth: '144px',
      height: '144px',
    },
  },
  textContainer: {
    width: '460px',
    textAlign: 'left',
    padding: '20px 0 20px 60px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
      padding: '0 30px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 25px',
    },
  },
  heading: {
    marginBottom: '2rem',
  },
}));

export default useStyles;
