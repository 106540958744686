import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading: {
    padding: '48px 0',
    [theme.breakpoints.down('md')]: {
      padding: '40px 0',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '30px 0',
    },
  },
  text: {
    whiteSpace: 'pre-wrap',
  },
}));

export default useStyles;
