import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { AnimatePresence, motion } from 'framer-motion';
import useStyles from './styles';

const MoeyHistoryMilestone = ({ milestone }) => {
  const classes = useStyles();

  return (
    <div className={classes.borderContainer}>
      <hr className={classes.border} />
      <Typography variant="h4" className={classes.milestone}>
        <span className={classes.invisibleText}>
          { milestone }
        </span>
        <AnimatePresence exitBeforeEnter>
          <motion.span
            key={milestone}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className={classes.text}
          >
            { milestone }
          </motion.span>
        </AnimatePresence>
      </Typography>
    </div>
  );
};

MoeyHistoryMilestone.propTypes = {
  milestone: PropTypes.string.isRequired,
};

export default MoeyHistoryMilestone;
