import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  mollyJoeyImage: {
    width: '600px',
    height: '400px',
    [theme.breakpoints.down('md')]: {
      width: '480px',
      height: '320px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '360px',
      height: '240px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '240px',
      height: '160px',
    },
  },
}));

export default useStyles;
