import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  inner: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '960px',
    width: '100%',
    position: 'relative',
    height: '240px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '884px',
      height: '192px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '284px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '236px',
    },
  },
  historyItemWrapper: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useStyles;
