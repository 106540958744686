import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backgroundContainer: {
    position: 'absolute',
    paddingTop: '120px',
    width: '100%',
    height: '520px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '100px',
      height: '400px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '60px',
      height: '310px',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '44px',
      height: '194px',
    },
  },
}));

export default useStyles;
