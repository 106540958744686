import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import SectionTitle from '@components/common/SectionTitle';
import GatsbyBackgroundImage from 'gatsby-background-image';
import GatsbyImagePropTypes from '@prop-types/gatsby-image';
import TestimonialsItem from './TestimonialsItem';

const TestimonialsSection = ({ testimonials, backgroundImage }) => (
  <section>
    <SectionTitle>
      Aw Shucks
    </SectionTitle>
    <GatsbyBackgroundImage fluid={backgroundImage} fadeIn={false}>
      <Grid container>
        {testimonials.map((testimonial, index) => (
          <TestimonialsItem
            key={testimonial.id}
            message={testimonial.message.message}
            organization={testimonial.organization}
            name={testimonial.name}
            odd={index % 2 === 1}
          />
        ))}
      </Grid>
    </GatsbyBackgroundImage>
  </section>
);

TestimonialsSection.propTypes = {
  testimonials: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    message: PropTypes.shape({
      message: PropTypes.string.isRequired,
    }).isRequired,
    organization: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  backgroundImage: GatsbyImagePropTypes.fluid.isRequired,
};

export default TestimonialsSection;
