import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const MollyJoeyTextContainer = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.boxWrapper}>
      <div className={classes.colorBar} />
      <div className={classes.textContainer}>
        { children }
      </div>
    </div>
  );
};

MollyJoeyTextContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MollyJoeyTextContainer;
