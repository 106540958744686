import React from 'react';
import GatsbyImage from 'gatsby-image';
import GatsbyImagePropTypes from '@prop-types/gatsby-image';
import useStyles from './styles';

const MollyJoeyImage = ({ image }) => {
  const classes = useStyles();

  return (
    <div className={classes.mollyJoeyImage}>
      <GatsbyImage
        fluid={image}
        alt="Molly and Joey"
      />
    </div>
  );
};

MollyJoeyImage.propTypes = {
  image: GatsbyImagePropTypes.fluid.isRequired,
};

export default MollyJoeyImage;
