import React from 'react';
import PropTypes from 'prop-types';
import useBreakpoints from '@utils/useBreakpoints';
import ArrowButton from '@components/common/ArrowButton';
import useStyles from './styles';

const MoeyHistoryController = ({ onArrowButtonClick }) => {
  const classes = useStyles();
  const { mdDown, smDown, xsDown } = useBreakpoints();

  return (
    <>
      <div className={`${classes.arrowButtonWrapper} ${classes.left}`}>
        <ArrowButton
          left
          size={xsDown ? 50 : smDown ? 60 : mdDown ? 70 : 80}
          onClick={onArrowButtonClick}
        />
      </div>
      <div className={`${classes.arrowButtonWrapper} ${classes.right}`}>
        <ArrowButton
          size={xsDown ? 50 : smDown ? 60 : mdDown ? 70 : 80}
          onClick={onArrowButtonClick}
        />
      </div>
    </>
  );
};

MoeyHistoryController.propTypes = {
  onArrowButtonClick: PropTypes.func.isRequired,
};

export default MoeyHistoryController;
