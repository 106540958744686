import React from 'react';
import PropTypes from 'prop-types';
import Layout from '@layouts/default';
import SEO from '@components/meta/SEO';
import { graphql } from 'gatsby';
import Wires from '@components/common/Wires';
import FirstWire from '@images/wire-about-1.png';
import SecondWire from '@images/wire-about-2.png';
import ThirdWire from '@images/wire-about-3.png';
import PageHeader from '@components/common/PageHeader';
import SectionSpacer from '@components/common/SectionSpacer';
import AppealingPointSection from '@components/common/AppealingPointSection';
import MollyJoeySection from '@components/about/MollyJoeySection';
import MoeyHistorySection from '@components/about/MoeyHistorySection';
import TestimonialsSection from '@components/about/TestimonialsSection';
import FeaturedProjectsSection from '@components/common/FeaturedProjectsSection';
import ContactBannerSection from '@components/common/ContactBannerSection';

const AboutPage = ({ data }) => {
  const aboutPage = data.aboutPage.edges[0].node;

  return (
    <Layout>
      <SEO title="About" />
      <Wires
        wires={[
          {
            src: FirstWire,
            side: 'left',
            top: 22,
          },
          {
            src: SecondWire,
            side: 'right',
            top: 41,
          },
          {
            src: ThirdWire,
            side: 'left',
            top: 75,
          },
        ]}
      />
      <PageHeader
        pageTitle="ABOUT MOEY"
        image={data.headerImage.childImageSharp.fluid}
      />
      <SectionSpacer />
      <AppealingPointSection
        imageAtLeft
        heading={aboutPage.theMoeyWaySectionHeading}
        text={aboutPage.theMoeyWaySectionText.theMoeyWaySectionText}
        image={aboutPage.theMoeyWaySectionImage.fluid}
        imageAlt={aboutPage.theMoeyWaySectionImage.title}
        noButton
      />
      <SectionSpacer />
      <MollyJoeySection
        heading={aboutPage.mollyJoeySectionHeading}
        text={aboutPage.mollyJoeySectionText.mollyJoeySectionText}
        backgroundImage={data.mollyJoeyBackgroundImage.childImageSharp.fluid}
        image={aboutPage.mollyJoeySectionImage.fluid}
      />
      <SectionSpacer />
      <MoeyHistorySection
        historyItems={aboutPage.moeyHistory}
      />
      <SectionSpacer />
      <TestimonialsSection
        testimonials={aboutPage.testimonials}
        backgroundImage={data.testimonialsBackgroundImage.childImageSharp.fluid}
      />
      <SectionSpacer />
      <FeaturedProjectsSection />
      <ContactBannerSection />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired, // eslint-disable-line
};

export default AboutPage;

export const pageQuery = graphql`
  query {
    aboutPage: allContentfulAboutPage(limit: 1) {
      edges {
        node {
          id
          theMoeyWaySectionHeading
          theMoeyWaySectionText {
            theMoeyWaySectionText
          }
          theMoeyWaySectionImage {
            fluid(maxWidth: 1152) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          mollyJoeySectionHeading
          mollyJoeySectionText {
            mollyJoeySectionText
          }
          mollyJoeySectionImage {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          testimonials {
            id
            message {
              message
            }
            organization
            name
          }
          moeyHistory {
            milestone
            heading
            description {
              description
            }
            image {
              fluid(maxWidth: 240) {
                ...GatsbyContentfulFluid_noBase64
              }
            }
          }
        }
      }
    }
    headerImage: file(relativePath: {eq: "page-header-about.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    mollyJoeyBackgroundImage: file(relativePath: {eq: "molly-joey-background.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    testimonialsBackgroundImage: file(relativePath: {eq: "testimonials-background.png"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
