import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './styles';

const TestimonialsItem = ({ odd, message, organization, name }) => {
  const classes = useStyles({ odd });

  return (
    <Grid item xs={12} md={4} className={classes.root}>
      <div className={classes.inner}>
        <Typography variant="body2" className={classes.message}>
          { message }
        </Typography>
        <Typography variant="h5">
          { organization }
        </Typography>
        <Typography variant="h5">
          { name }
        </Typography>
      </div>
    </Grid>
  );
};

TestimonialsItem.defaultProps = {
  odd: false,
};

TestimonialsItem.propTypes = {
  odd: PropTypes.bool,
  message: PropTypes.string.isRequired,
  organization: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default TestimonialsItem;
