import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import GatsbyImagePropTypes from '@prop-types/gatsby-image';
import MollyJoeyBackground from './MollyJoeyBackground';
import MollyJoeyImage from './MollyJoeyImage';
import MollyJoeyTextContainer from './MollyJoeyTextContainer';
import useStyles from './styles';

const MollyJoeySection = ({ heading, text, backgroundImage, image }) => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <MollyJoeyBackground
        backgroundImage={backgroundImage}
      />
      <MollyJoeyImage
        image={image}
      />
      <MollyJoeyTextContainer>
        <>
          <Typography variant="h3" className={classes.heading} align="center">
            { heading }
          </Typography>
          <Typography variant="body2" className={classes.text} paragraph>
            { text }
          </Typography>
        </>
      </MollyJoeyTextContainer>
    </section>
  );
};

MollyJoeySection.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  backgroundImage: GatsbyImagePropTypes.fluid.isRequired,
  image: GatsbyImagePropTypes.fluid.isRequired,
};

export default MollyJoeySection;
